<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="b-pub-tt flex inc">
            <span class="bt">基本情况介绍</span>
          </div>
          <div class="nhjj">
            <div class="mySwipbox">
              <div class="text inner-container">
                <br />
                <p>
                  湖南岳阳洞庭湖旅游度假区是1992年10月经省政府批准建立的首家省级旅游度假区，于2023年1月正式晋级为国家级旅游度假区，目前是全省仅有的三家国家级旅游度假区之一，有“东方日内瓦”之称。现管辖面积56.18平方公里，有5个街道办事处、管理处，22个社区、2个村，常住人口10万。
                </p>
                <p>
                  南湖是岳阳城市的生态福地。新区境内的南湖属东洞庭湖子湖（古称巴陵㴩湖），是全国少有的城市天然湖泊，2018年，南湖与意大利科莫湖、芬兰塞马湖等一起被评为“国际魅力湖泊”。境内还有城市“绿肺”——龙山，与南湖共同构成以“一山一水”为标志的岳阳城市生态核心。
                </p>
                <p>
                  南湖是岳阳城市的文旅胜地。境内有以龙山、龙舟、“一龙九龟”传说为主题的龙文化，以4A级景区圣安寺、茶博城和湖滨百年教会学校为载体的宗教文化和茶文化，以尚书坊、三眼桥为标志的法治精神和廉洁文化等。唐代诗人李白还在此留下“南湖秋水夜无烟，耐可乘流直上天”和“水闲明镜转，云绕画屏移”的著名诗篇。近年来建有集观光车道、骑行道、步行道于一体的南湖三圈，南湖十景、洞庭湖小镇、3A级景区花语世界、星球乐园、万物制造局、茶韵小镇、赊月公园、清华美院、优乐文旅产业园、国家名片周令钊艺术馆、五星级铂尔曼酒店等旅游休闲景点。
                </p>
                <p>
                  南湖是岳阳城市的宜居美地。岳阳洞庭湖旅游度假区是岳阳城市南延提质扩容战略的关键载体，随着岳阳市中心医院、岳阳学院、岳阳开放大学等多个市级重点项目启动建设，天灯咀半岛综合开发、洞庭文旅国际会展中心、太平咀国际旅游金融艺术中心等战略项目相继落地，湘一南湖学校、湖师大附属南湖学校、岳阳市第二十中学（南湖新区实验小学）等一批公共服务项目相继落户，是岳阳城市人气最旺的“会客厅”。
                </p>
                <br />
              </div>
            </div>
            <ul class="mod1-gk">
              <li>
                <img src="../assets/images/wt3.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">常住人口</h6>
                  <p class="num" style="color: #489ffe">10万</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt4.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">管辖面积</h6>
                  <p class="num" style="color: #85b1ff">56.18平方千米</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt4.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">水域面积</h6>
                  <p class="num" style="color: #90ffad">13.78平方公里</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">湖岸线总长</h6>
                  <p class="num" style="color: #ffc55a">28.2公里</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">年平均气温</h6>
                  <p class="num" style="color: #45f5ff">16度-22度</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">年平均湿度</h6>
                  <p class="num" style="color: #45f5ff">79%</p>
                </div>
              </li>
              <!-- <li>
                <img src="../assets/images/wt5.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">街道办事处</h6>
                  <p class="num" style="color: #45f5ff">3个</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/sy_16.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">区直单位</h6>
                  <p class="num" style="color: #45f5ff">29个</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/sy_16.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">市直派驻单位</h6>
                  <p class="num" style="color: #45f5ff">9个</p>
                </div>
              </li> -->
            </ul>
          </div>
          <div class="b-pub-tt flex inc">
            <span class="bt">南湖略影</span>
          </div>
          <div class="swiper-container swiper mySwiper3">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1">
                <img
                  src="https://media.shouxinfund.com/yueyang/guiyinone.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/sanyanone.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/tiandengone.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/shenganone.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  src="https://media.shouxinfund.com/yueyang/nanjinone.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  src="https://media.shouxinfund.com/yueyang/taibaione.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  src="https://media.shouxinfund.com/yueyang/yilongone.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/maigangone.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/baxianone.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/xunmeione.jpg" alt="" />
              </div>
            </div>
            <!-- <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/范蠡归隐.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/三眼阅秀.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/天灯引航.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/圣安塔影.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/南津古渡.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/太白赊月.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/一龙九龟.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/麦港飞舟.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="../assets/imgs/八仙问道.jpg" alt="" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen mod3">
          <div class="cen-menu">
            <span class="lk">智慧党建</span>
            <span class="lk cur">智慧文旅</span>
            <span class="lk">新时代云平台</span>
          </div>
          <div class="cen-top cen-wr">
            <li>
              <h6 class="tit">地区生产总值<br />(亿元)</h6>
              <p class="num">136</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">社会消费品零售总额<br />(亿元)</h6>
              <p class="num">48.97</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">一般公共预算地方收入<br />(亿元)</h6>
              <p class="num">3.46</p>
            </li>

            <li>
              <h6 class="tit">年累计游客(万人)</h6>
              <p class="num">702.31</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">月累计游客(万人)</h6>
              <p class="num">58.53</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">人均消费(元/人)</h6>
              <p class="num">342.30</p>
            </li>
          </div>
          <div class="mod3-map">
            <div class="yueyang_map"></div>
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen1.png"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen2.png"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen3.png"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen4.png"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen5.png"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen6.png"
                    alt=""
                    class="big_img"
                  />
                </div>

                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen7.png"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen8.png"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen9.png"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://home/ResImg/wen10.png"
                    alt=""
                    class="small_img"
                  />
                </div>
              </div>
              <!-- <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（南津古渡）1.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（㴩阁寻梅）4.jpg"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（范蠡归隐）5.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（天灯引航）9.jpg"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（三眼阅秀）6.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（八仙问道）3.jpg"
                    alt=""
                    class="big_img"
                  />
                </div>

                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（圣安塔影）2.jpg"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（一龙九龟）10.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（太白赊月）7.jpg"
                    alt=""
                    class="big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/imgs/文旅（麦港飞舟）8.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
              </div> -->
            </div>
          </div>
          <ul class="mod3-bot">
            <li>
              <img src="../assets/images/wlv_17.png" class="icon" alt="" />
              <span>景区景点</span>
            </li>
            <!-- <li>
              <img src="../assets/images/wlv_18.png" class="icon" alt="" />
              <span>交通接驳</span>
            </li> -->
            <li>
              <img src="../assets/images/wlv_19.png" class="icon" alt="" />
              <span>住宿</span>
            </li>
            <li>
              <img src="../assets/images/wlv_20.png" class="icon" alt="" />
              <span>餐饮</span>
            </li>
            <li>
              <img src="../assets/images/wlv_21.png" class="icon" alt="" />
              <span>公共服务</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod4 flex-1 flex flex-v">
          <div class="b-pub-tt flex inc">
            <span class="bt">南湖十景</span>
          </div>
          <div class="flex flex-pack-justify flex-1">
            <div class="detail">
              <div class="col col1">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">㴩阁寻梅</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container2">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/xunmeione.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/xunmeione.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col2">
                <div class="b-smtit">
                  <span class="bt b-smtit1">范蠡归隐</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container5">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/yueyang/guiyinone.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/yueyang/guiyinone.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col3">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">天灯引航</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container9">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/tiandengone.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/tiandengone.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col4">
                <div class="b-smtit">
                  <span class="bt b-smtit1">三眼阅秀</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container3">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/sanyanone.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/sanyanone.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col5">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">八仙问道</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container4">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/baxianone.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/baxianone.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col6">
                <div class="b-smtit">
                  <span class="bt b-smtit1">南津古渡</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container7">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/yueyang/nanjinone.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/yueyang/nanjinone.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col7">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">圣安塔影</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container8">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/shenganone.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/shenganone.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col8">
                <div class="b-smtit">
                  <span class="bt b-smtit1">一龙九龟</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container6">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/yueyang/yilongone.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/yueyang/yilongone.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col9">
                <div class="b-smtit">
                  <span class="bt b-smtit1 b-smtit2">太白赊月</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container10">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/yueyang/taibaione.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/yueyang/taibaione.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col10">
                <div class="b-smtit">
                  <span class="bt b-smtit1">麦港飞舟</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container11">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/maigangone.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/maigangone.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Head from "@/components/head_big.vue";
let Echarts = require("echarts/lib/echarts");
// import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      // headTitle: "",
      headTitle: "智 慧 文 旅",
      // 月游客
      mVisitor: "",
      // 人均消费
      personCon: "",
      // 年度游客
      yVisitor: "",
      dataList: [
        {
          title: "常驻人口",
          content: "10万",
        },
        {
          title: "土地总面积",
          content: "56.18平方千米",
        },
        {
          title: "水域面积",
          content: "13.78平方公里",
        },
        {
          title: "湖岸线总长",
          content: "28.2公里",
        },
        {
          title: "年平均气温",
          content: "16度-22度",
        },
        {
          title: "年平均湿度",
          content: "79%",
        },
      ],
      swiper1: [],
      swiper2: [],
      swiper3: [],
      swiper4: [],
      swiper5: [],
      swiper6: [],
      swiper7: [],
      swiper8: [],
      swiper9: [],
      swiper10: [],
      swiper11: [],
      mySwiper1: [],
      mySwiper2: [],
    };
  },
  components: {
    Head,
  },
  computed: {},
  created() {
    console.log("document.body.clientWidth", document.body.clientWidth);
    console.log("window.screen.width", window.screen.width);
  },
  mounted() {
    // 获取智慧文旅的信息
    this.getwenlvData();
    this.swiper1 = new Swiper(".swiper-container.swiper-container1", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      speed: 3000,
      slidesPerView: 1,
      spaceBetween: -10,
      slidesPerColumn: 1,
      autoplay: 10000,
      loop: true,
      centeredSlides: true,
      slidesOffsetAfter: 100,
    });
    this.swiper2 = new Swiper(".swiper-container.swiper-container2", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper3 = new Swiper(".swiper-container.swiper-container3", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper4 = new Swiper(".swiper-container.swiper-container4", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper5 = new Swiper(".swiper-container.swiper-container5", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper6 = new Swiper(".swiper-container.swiper-container6", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper7 = new Swiper(".swiper-container.swiper-container7", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper8 = new Swiper(".swiper-container.swiper-container8", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper9 = new Swiper(".swiper-container.swiper-container9", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper10 = new Swiper(".swiper-container.swiper-container10", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper11 = new Swiper(".swiper-container.swiper-container11", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.mySwiper2 = new Swiper(".swiper-container.mySwiper2", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      autoplay: {
        // 自动滑动
        stopOnLastSlide: false,
        delay: 10000, //5秒切换一次
        disableOnInteraction: false,
      },
      autoplay: 10000,
      centeredSlides: true,
      direction: "vertical",
      grabCursor: true,
      autoplayDisableOnInteraction: false,
      // mousewheelControl:true,
      autoHeight: true,
      observer: true,
      observeParents: true,
      speed: 5000,
      loop: true,
      // scrollbar: ".swiper-scrollbar",
      // direction: "vertical",
      // slidesPerView: "auto",
      // // mousewheelControl: true,
      // // freeMode: true,
      // roundLengths: true,
    });
    this.mySwiper3 = new Swiper(".swiper-container.mySwiper3", {
      // loop: true,
      // speed: 4000,
      // loopedSlides: 4, // looped slides should be the same
      // spaceBetween: 10,
      // centeredSlides: true,
      // slidesPerView: "auto",
      // touchRatio: 0.2,
      // slideToClickedSlide: true,
      // autoplay: true,
      /**
       * slidesPerView : 4,
      spaceBetween : 20,
      speed: 3000,
      autoplay: true,
      loop: true,
       */
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "coverflow",
      slidesPerView: 3,
      centeredSlides: true,
      coverflow: {
        rotate: 30,
        stretch: 10,
        depth: 60,
        modifier: 2,
        slideShadows: true,
      },
      speed: 3000,
      autoplay: true,
      loop: true,
    });
    window.addEventListener("resize", () => {
      // this.chart1.resize();
      // this.chart2.resize();
    });
  },

  methods: {
    // 获取智慧文旅的信息
    getwenlvData() {
      const res = axios.get("/10zhwl.json").then(
        (res) => {
          console.log("请求成功了", res.data);
          // this.headTitle=res.data.bigTitle
          this.mVisitor = res.data.mVisitor;
          this.personCon = res.data.personCon;
          this.yVisitor = res.data.yVisitor;
        },
        (err) => {
          console.log("请求失败了", err.message);
        }
      );
    },
  },
};
</script>

<style scoped>
.page {
  background: url(../assets/images/wlv_221.jpg) no-repeat center;
  background-size: 100% 100%;
  background-color: #0a224b;
}

.mod1 .nhjj .mySwiper2 {
    width: 100%;
    height: 35%;
  }
  .mod1 .nhjj .mySwiper2 .swiper-slide2 {
    font-size: 48px;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px;
    font-family: microsoft yahei;
  }
  .mySwipbox {
    height: 63%;
    overflow: hidden;
  }
  .mySwipbox .text {
    width: 100%;
    height: 100%;
    font-size: 42px;
    color: #fff;
    letter-spacing: 2px;
    font-family: SimSun;
  }
  .mySwipbox .text p {
    text-indent: 2em;
    font-size: 42px;
    font-family: SimSun;
  }
  .mod1-gk {
    /* margin-top: 10%; */
    width: 100%;
    /* height: 20%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
  }
  .mod1-gk li {
    margin-top: 2%;
    width: 33%;
    display: flex;
    align-items: center;
    /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px; */
  }
  .cen-top{
    margin-top: 1%;
  }
  .mod4 .col .swiper-container {
    /* margin-top: 12%; */
    width: 90%;
    height: 50%;
  }
  .mod4 .detail .swiper-container .swiper-slide {
    border: 2px solid #15386e;
    border-radius: 10px;
    width: 100%;
    height: 50%;
  }
  .mod4 .detail .swiper-container .swiper-slide img {
    width: 100%;
    height: 140px;
    border-radius: 10px;
  }

.mod1 {
  height: 100%;
}
.mod1 .swiper {
  width: 100%;
  margin-top: 2.5%;
}
.mod1 .inc,
.mod4 .inc {
  height: 5%;
}
.mod1 .nhjj {
  height: 70%;
  width: 100%;
}

.mod1-gk .icon {
  width: 120px;
  height: 120px;
}
.mod1-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod1-gk .tit {
  font-size: 20px;
  color: #fff;
}
.mod1-gk .num {
  margin-top: 2%;
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}
.mySwiper2 .swiper-slide1 img {
  border-radius: 50%;
  box-shadow: 0px 10px 10px 10px #8f8282;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  height: 60%;
  object-fit: cover;
}

.mod1 .mySwiper3 {
  height: 20%;
  /* box-sizing: border-box; */
  /* padding: 10px 0; */
}
.mod1 .mySwiper3 .swiper-slide {
  width: 25%;
}
.mod1 .mySwiper3 .swiper-slide img {
  width: 100%;
  /* height: 80%; */
}
.mod1 .mySwiper3 .swiper-wrapper {
  height: auto;
}
.mod2 {
  height: 55vh;
}
.mod2 .list {
  height: 55vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mod2 .list .item {
  width: 48%;
  height: 22.5vh;
  height: calc(50% - 15px);
}
.mod2 .list .item:nth-child(1),
.mod2 .list .item:nth-child(2) {
  margin-bottom: 20px;
}
.mod2 .list .chart {
  width: 100%;
  height: calc(100% - 50px);
}

.mod2 .fenbu {
  width: 100%;
  height: calc(100% - 50px);
}

.con-c {
  position: relative;
}

.mod3-bot {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 120px;
  padding: 0 4%;
  z-index: 10;
}
.mod3-bot li {
  display: flex;
  align-items: center;
}
.mod3-bot .icon {
  width: 44px;
  margin-right: 16px;
}
.mod3-bot li span {
  font-size: 26px;
  color: #fff;
  font-family: "RuiZiZhenYan";
  letter-spacing: 3px;
}

.mod3-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.mod3-map .yueyang_map {
  opacity: 0.6;
  margin: 10% auto;
  width: 80%;
  height: 80%;
  background: url(../assets/imgs/yueyang_map.webp) no-repeat center;
  /* background:url(../assets/imgs/yueyang_map1.png) no-repeat center; */
  background-size: 100% 100%;
}
.mod3-map .swiper-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 100%;
  height: 60%;
  /* background-color: #15386e; */
  overflow: hidden;
  /* margin-top: 50px; */
  width: 80%;
}
.mod3-map .swiper-container .swiper-wrapper {
  margin: 0 auto;
}
.mod3-map .swiper-container .swiper-slide {
  position: relative;
  width: 80%;
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
}
.mod3-map .swiper-container .swiper-slide .text {
  width: 200px;
  height: 300px;
  position: absolute;
  top: 400px;
  right: 230px;
}
.mod3-map .swiper-container .swiper-slide .big_img {
  width: 95%;
  height: 70%;
  image-rendering: optimizeQuality;
  border: 5px solid #8f8282;
  border-radius: 120px 120px 120px 120px;
}
.mod3-map .swiper-container .swiper-slide .small_img {
  width: 95%;
  height: 70%;
  image-rendering: optimizeQuality;
  border: 2px solid #15386e;
  border-radius: 120px 120px 120px 120px;
}

.mod4 {
  height: 100%;
}
.mod4 .detail {
  width: 95%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.mod4 .col {
  width: 50%;
  height: 20%;
}
/* .mod4 .col .swiper-container {
  width: 50%;
  height: 50%;
} */
/* .mod4 .col .swiper-container {
  margin-top: 6%;
  width: 100%;
  height: 60%;
} */

.mod4 .col .b-smtit1 {
  width: 200px;
  display: block;
  margin: 0 auto;
}
.mod4 .col .b-smtit2 {
  width: 200px;
  color: #e5528b;
  display: block;
  margin: 0 auto;
}
.mod4 .col1 .bar-list .font1,
.mod4 .col2 .bar-list .font1 {
  display: flex;
  justify-content: space-around;
}
.mod4 .list {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 15px;
}
.mod4 .list .item {
  position: relative; /* width: 185px;height: 160px; */ /* margin:0 0 25px 15px; */
}
.mod4 .list .title {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #15386e;
  padding: 5px 5px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.mod6 {
  height: 30%;
}
.mod6 .col {
  width: 46%;
}
.mod6 col .bar-list .smbox .font1 {
  display: flex;
  justify-content: space-around;
}
.inner-container {
  animation: myMove 25s linear infinite;
  animation-fill-mode: forwards;
}

@keyframes myMove {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1000px);
  }
}

@media screen and (max-width: 2720px) {
  .mod1 .nhjj .mySwiper2 {
    width: 100%;
    height: 35%;
  }
  .mod1 .nhjj .mySwiper2 .swiper-slide2 {
    font-size: 48px;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px;
    font-family: microsoft yahei;
    /* line-height: 1.8; */
  }

  .swiper-slide2 .text {
  }
  .mod1-gk {
    /* margin-top: 10%; */
    width: 100%;
    /* height: 20%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
  }
  .mod1-gk li {
    margin-bottom: 5%;
    width: 33%;
    display: flex;
    align-items: center;
    /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px; */
  }
  .cen-top{
    margin-top: 10%;
  }
  .mod4 .col .swiper-container {
    margin-top: 6%;
    width: 100%;
    height: 150%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .mod4 .detail .swiper-container .swiper-slide {
    border: 2px solid #15386e;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
  .mod4 .detail .swiper-container .swiper-slide img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
}
</style>
